import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../../pages/HomePage";
const NotFoundPage = React.lazy(() => import("../../pages/NotFoundPage.js"));
const AboutPage = React.lazy(() => import("../../pages/AboutPage.js"));
const ContactPage = React.lazy(() => import("../../pages/ContactPage.js"));
const ServiceDetailsPage = React.lazy(() =>
  import("../../pages/ServiceDetailsPage.js")
);

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <Suspense fallback={<div></div>}>
            {" "}
            <NotFoundPage />{" "}
          </Suspense>
        }
      />
      <Route path="/" element={<HomePage />} />
      <Route
        path="/about-us"
        element={
          <Suspense fallback={<div></div>}>
            {" "}
            <AboutPage />
          </Suspense>
        }
      />
      <Route
        path="/contact-us"
        element={
          <Suspense fallback={<div></div>}>
            {" "}
            <ContactPage />
          </Suspense>
        }
      />
      <Route
        path="/services/:title"
        element={
          <Suspense fallback={<div></div>}>
            {" "}
            <ServiceDetailsPage />{" "}
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
