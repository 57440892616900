import { useState, useEffect } from "react";
import Layout from "./components/Layout/Layout";
import AppRoutes from "./components/Routes/AppRoutes";
import "./index.css";

function App() {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = (event) => {
      setScrollTop(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return <Layout children={<AppRoutes />} scrollTop={scrollTop} />;
}

export default App;
