import "./Footer.css";

const Footer = () => {
  return (
    <div
      className="container-fluid bg-dark text-light footer pt-5 wow fadeIn"
      data-wow-delay="0.1s"
      style={{ marginTop: "6rem" }}
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Contacts</h4>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>7A Oduduwa Road GRA
              Apapa Lagos
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>+2348023078123
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>+2348139617001
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>+2347033367305
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>durotrade@yahoo.com
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>
              <span style={{ fontSize: "13px" }}>
                info.durotradelogistic@gmail.com
              </span>
            </p>
            <div className="d-flex pt-2">
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-twitter"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-youtube"></i>
              </a>
              <a className="btn btn-outline-light btn-social" href="">
                <i className="fab fa-linkedin-in"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Services</h4>
            <a className="btn btn-link" href="/services/air-freight">
              Air Freight
            </a>
            <a className="btn btn-link" href="/services/sea-freight">
              Sea Freight
            </a>
            <a className="btn btn-link" href="/services/road-freight">
              Road Freight
            </a>
            <a className="btn btn-link" href="/services/warehouse-solution">
              Warehouse Solution
            </a>
            <a className="btn btn-link" href="/services/custom-clearance">
              Custom Clearance
            </a>
            <a className="btn btn-link" href="/services/train-freight">
              Train Freight
            </a>
            <a className="btn btn-link" href="/services/consultancy">
              Consultancy
            </a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Quick Links</h4>
            <a className="btn btn-link" href="/about-us">
              About Us
            </a>
            <a className="btn btn-link" href="/contact-us">
              Contact Us
            </a>

            <a className="btn btn-link" href="#">
              Terms & Condition
            </a>
            <a className="btn btn-link" href="/contact-us">
              Support
            </a>
          </div>
          <div className="col-lg-3 col-md-6">
            <h4 className="text-light mb-4">Newsletter</h4>
            <p>Service not available yet</p>
            <div
              className="position-relative mx-auto"
              style={{ maxWidth: "400px" }}
            >
              <input
                className="form-control border-0 w-100 py-3 ps-4 pe-5"
                type="text"
                placeholder="Your email"
                disabled
              />
              <button
                type="button"
                disabled
                className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
              >
                SignUp
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          <div className="row">
            <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
              &copy;{" "}
              <a className="border-bottom" href="#">
                Durotade Nigeria Limited
              </a>
              , All Right Reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
