import React from "react";
import tracker from "../../../Assets/tracker.png";
import blog from "../../../Assets/blog.png";
import cost from "../../../Assets/cost.jpg";

const Support = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="text-secondary text-uppercase">Support Services</h6>
          <h1 className="mb-5">Our Extra support Services</h1>
        </div>
        <div className="row g-4">
          <div
            className="col-lg-4 col-md-4 col-sm-6 col-12 wow fadeInUp"
            data-wow-delay="0.3s"
          >
            <div className="team-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={tracker} alt="" />
              </div>
              <h5 className="mb-0">Track your item</h5>
              <p>
                Track all your shipped items both locally and at international
                level
              </p>
              <div className="btn-slide mt-1">
                <i className="fa fa-share"></i>
                <span>
                  <a
                    href="https://www.track-trace.com/"
                    target="_blank"
                    title="Track you shipping "
                  >
                    <i class="fa-solid fa-truck"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-lg-4 col-md-4 col-sm-6 col-12 wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="team-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={blog} alt="" />
              </div>
              <h5 className="mb-0">Our Blogs</h5>
              <p>
                Read blogs and updates on shipping and logistic news globally
              </p>
              <div className="btn-slide mt-1">
                <i className="fa fa-share"></i>
                <span>
                  <a
                    href="/blogs"
                    title="get updated blogs relating to shipping and logistics locally and at global level"
                  >
                    <i class="fa-brands fa-blogger"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>

          <div
            className="col-lg-4 col-md-4 col-sm-6 col-12 wow fadeInUp"
            data-wow-delay="0.9s"
          >
            <div className="team-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={cost} alt="image_of_cost" />
              </div>
              <h5 className="mb-0">Cost of shipping</h5>
              <p>
                Get cost of shipping with ease directly from our customer
                support service representatives
              </p>
              <div className="btn-slide mt-1">
                <i className="fa fa-share"></i>
                <span>
                  <a
                    href="/contact-us"
                    title="Contact us to get shipping details"
                  >
                    <i class="fa-solid fa-phone"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
