import feature from "../../../Assets/feature.jpg";

const Features = () => {
  return (
    <div className="container-fluid overflow-hidden py-5 px-lg-0">
      <div className="container feature py-5 px-lg-0">
        <div className="row g-5 mx-lg-0">
          <div
            className="col-lg-6 feature-text wow fadeInUp"
            data-wow-delay="0.1s"
          >
            <h6 className="text-secondary text-uppercase mb-3">Our Features</h6>
            <h1 className="mb-5">
              We Are Trusted Logistics Company Since 2013
            </h1>
            <div className="d-flex mb-5 wow fadeInUp" data-wow-delay="0.3s">
              <i className="fa fa-globe text-primary fa-3x flex-shrink-0"></i>
              <div className="ms-4">
                <h5>Worldwide Service</h5>
                <p className="mb-0">
                  We provide a wide range services to our esteemed customers
                  globally
                </p>
              </div>
            </div>
            <div className="d-flex mb-5 wow fadeIn" data-wow-delay="0.5s">
              <i className="fa fa-shipping-fast text-primary fa-3x flex-shrink-0"></i>
              <div className="ms-4">
                <h5>On Time Delivery</h5>
                <p className="mb-0">
                  We have and always maintain focus on on-time-delivery of our
                  services. As we place a high value on time and the effect it
                  could have on our customers business
                </p>
              </div>
            </div>
            <div className="d-flex mb-0 wow fadeInUp" data-wow-delay="0.7s">
              <i className="fa fa-headphones text-primary fa-3x flex-shrink-0"></i>
              <div className="ms-4">
                <h5>24/7 Telephone Support</h5>
                <p className="mb-0">
                  Experience an out of the box customer relationship full of
                  enthusiasm, customer emphathy & flexibility in services both
                  on direct phone call and other online channels
                </p>
              </div>
            </div>
          </div>
          <div
            className="col-lg-6 pe-lg-0 wow fadeInRight"
            data-wow-delay="0.1s"
            style={{ minHeight: "400px" }}
          >
            <div className="position-relative h-100">
              <img
                className="position-absolute img-fluid w-100 h-100"
                src={feature}
                style={{ objectFit: "cover" }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
