import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { HashLink } from "react-router-hash-link";
import "./MainNav.css";

const MainNav = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow border-top border-5 border-primar sticky-top p-0">
      <NavLink
        to="/"
        className="navbar-brand bg-primar d-flex align-items-center px-2 px-sm-4 px-lg-5"
      >
        <HashLink smooth to="/#about">
          <h2 className="mb-2 text-white nav-text"> DUROTRADE NIG LTD</h2>
        </HashLink>
      </NavLink>
      <button
        type="button"
        className="navbar-toggler me-4"
        data-bs-toggle="collapse"
        data-bs-target="#navbarCollapse"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <Link
            activeClass="active"
            className={`nav-item nav-link mouse-pointer`}
            to="intro"
            spy={true}
            smooth={true}
            hashSpy={true}
            duration={500}
            delay={500}
            isDynamic={true}
            ignoreCancelEvents={false}
            spyThrottle={500}
          >
            Home
          </Link>

          <Link
            activeClass="active"
            className={`nav-item nav-link mouse-pointer`}
            to="about"
            spy={true}
            smooth={true}
            hashSpy={true}
            duration={500}
            delay={500}
            isDynamic={true}
            ignoreCancelEvents={false}
            spyThrottle={500}
          >
            About
          </Link>

          <Link
            activeClass="active"
            className={`nav-item nav-link mouse-pointer`}
            to="services"
            spy={true}
            smooth={true}
            hashSpy={true}
            duration={500}
            delay={500}
            isDynamic={true}
            ignoreCancelEvents={false}
            spyThrottle={500}
          >
            Services
          </Link>

          <Link
            activeClass="active"
            className={`nav-item nav-link mouse-pointer`}
            to="footer"
            spy={true}
            smooth={true}
            hashSpy={true}
            duration={500}
            delay={500}
            isDynamic={true}
            ignoreCancelEvents={false}
            spyThrottle={500}
          >
            Contact
          </Link>
        </div>
        <h4 className="m-0 pe-lg-5 d-none d-lg-block">
          <i className="fa fa-headphones text-primary me-3"></i>
          <a href="Tel: +2348023078123"> +234 8023 078 123</a>
        </h4>
      </div>
    </nav>
  );
};

export default MainNav;
