import { Fragment } from "react";
import { useLocation } from "react-router-dom";
import MainNav from "./MainNav/MainNav";
import Footer from "./Footer/Footer";
import BackToTop from "../UI/BackToTop";
import Banner from "./Banner/Banner";
import { SEO } from "../../helpers/SEO";

const Layout = ({ children, scrollTop }) => {
  const location = useLocation();
  const { pathname } = location;

  let titleData;

  if (pathname === "/") {
    titleData = {
      title: "Durotrade Nig Limited - Home",
      metaDescription:
        "Durotrade Nigeria Limited homepage. A leading logistic company in Nigeria based in lagos state that deals in all freigh and logistic services to both individuals and organizations of all sizes",
    };
  } else if (pathname === `/about-us`) {
    titleData = {
      title: `Durotrade Nig Limited - About Durotrade logistics`,
      metaDescription:
        "Brief details about Durotrade Logistic Nigeria Limited. A leading logistic company in lagos Nigeria",
    };
  } else if (pathname === `/contact-us`) {
    titleData = {
      title: `Durotrade Nig Limited - Contact Durotrade Nig Limited`,
      metaDescription:
        "Contact Durotrade Nigeria Limited on the provided contact details on our website, we always ensure we reach out to our esteemed customers as quick as possible",
    };
  } else if (pathname === `/services/${pathname.split("/")[2]}`) {
    titleData = {
      title: `Durotrade Nig Limited - Read about durotrade ${
        pathname.split("/")[2]
      } service`,
      metaDescription: `Durotrade Nigeria Limited offers ${
        pathname.split("/")[2]
      } service to our esteemed customers both at local and international level`,
    };
  } else {
    titleData = {
      title: "404 Error - Page not found",
      metaDescription: "Page not found",
    };
  }

  SEO(titleData);

  return (
    <Fragment>
      <header className="fixed-top">
        <MainNav />
      </header>
      {location.pathname !== "/" && <Banner pathName={location.pathname} />}
      <main>{children}</main>
      <footer>
        <Footer />
      </footer>

      <BackToTop scrollTop={scrollTop} location={location.pathname} />
    </Fragment>
  );
};

export default Layout;
