import { Link } from "react-router-dom";

const ServerCard = (props) => {
  return (
    <div className="col-md-6 col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
      <div className="service-item p-4">
        <div className="overflow-hidden mb-4">
          <img className="img-fluid" src={props.imagePath} alt="" />
        </div>
        <h4 className="mb-3">{props.title}</h4>
        <p>{props.description}</p>
        <Link
          className="btn-slide mt-2"
          to={`services/${props.url}`}
          title={`read more about our ${props.title} service`}
        >
          <i className="fa fa-arrow-right"></i>
          <span>View Details</span>
        </Link>
      </div>
    </div>
  );
};

export default ServerCard;
