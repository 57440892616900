import dummyImg from "../../../Assets/dummy-image.jpg";

const Team = () => {
  return (
    <div className="container-xxl py-5">
      <div className="container py-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="text-secondary text-uppercase">Our Team</h6>
          <h1 className="mb-5">Expert Team Members</h1>
        </div>
        <div className="row g-4">
          <div
            className="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="0.3s"
          ></div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div className="team-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={dummyImg} alt="" />
              </div>
              <h5 className="mb-0">Saheed Abolarin</h5>
              <p>CEO </p>
              <div className="btn-slide mt-1">
                <i className="fa fa-share"></i>
                <span>
                  <a href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
            <div className="team-item p-4">
              <div className="overflow-hidden mb-4">
                <img className="img-fluid" src={dummyImg} alt="" />
              </div>
              <h5 className="mb-0">Habeed Abolarin</h5>
              <p>Manager</p>
              <div className="btn-slide mt-1">
                <i className="fa fa-share"></i>
                <span>
                  <a href="">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="">
                    <i className="fab fa-instagram"></i>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div
            className="col-lg-3 col-md-6 wow fadeInUp"
            data-wow-delay="0.9s"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Team;
