import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import carousel1 from "../../../Assets/carousel-1.jpg";
import Navigator from "./Navigator";

const Carousel1 = ({ onToggleCarousel }) => {
  return (
    <div className="position-relative owl-carousel-item mt-5">
      <img className="img-fluid" src={carousel1} alt="" />
      <div
        className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
        style={{ background: "rgba(6, 3, 21, .5)" }}
      >
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-10 col-lg-8">
              <AnimationOnScroll animateIn="animate__fadeInDown">
                <h5 className="text-white text-uppercase mb-3 animated slideInDown">
                  DuroTrade Nigeria Limited
                </h5>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="animate__fadeInRight">
                <h1 className="display-3 text-white animated slideInDown mb-4">
                  #1 Place For Your{" "}
                  <span className="text-primary">Shipping</span> Solution
                </h1>
                <p className="fs-5 fw-medium text-white mb-4 pb-2">
                  We are duly register with Nigeria Custom Service, Nigeria Port
                  Authority and NIMASA
                </p>
              </AnimationOnScroll>

              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <Link
                  to="/about-us"
                  className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                >
                  Explore More...
                </Link>
              </AnimationOnScroll>
            </div>
            <Navigator onToggleCarousel={onToggleCarousel} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel1;
