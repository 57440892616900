import { Fragment } from "react";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import "./UI.css";

const BackToTop = ({ scrollTop, location }) => {
  return (
    <Fragment>
      {location !== "/" && (
        <NavLink
          to="/"
          className="btn btn-primar2 object btn-lg-square back-to-top"
          title="Go back home"
        >
          <i class="fa-solid fa-arrow-left-long"></i>
        </NavLink>
      )}

      <a
        href="https://wa.link/tpz4bf"
        className="btn btn-primar btn-lg-square back-to-top"
        rel="noreferrer"
        target="_blank"
        title="Have a direct chat with us on whatsapp"
      >
        <i class="fa-brands fa-whatsapp wa-link"></i>
      </a>
      <Link
        activeClass="active"
        className={`btn2 back-to-top ${
          scrollTop > 200 ? "fadeIn" : "fadeOut"
        } `}
        to="intro"
        spy={true}
        smooth={true}
        delay={500}
        duration={500}
        isDynamic={true}
        ignoreCancelEvents={false}
        spyThrottle={500}
      >
        <i class="fa fa-chevron-up"></i>
      </Link>
    </Fragment>
  );
};

export default BackToTop;
