import React from "react";
import ServerCard from "./ServiceCard";
import { services } from "./ServiceList";

const Services = () => {
  return (
    <div className="container-xxl py-5 services">
      <div className="container py-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="text-secondary text-uppercase">Our Services</h6>
          <h1 className="mb-5">Explore Our Services</h1>
        </div>
        <div className="row g-4">
          {services.map((service) => {
            return (
              <ServerCard
                key={service.id}
                title={service.title}
                description={service.description}
                imagePath={service.imagePath}
                url={service.url}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Services;
