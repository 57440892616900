import service1 from "../../../Assets/service-1.jpg";
import service2 from "../../../Assets/service-2.jpg";
import service3 from "../../../Assets/service-3.jpg";
import service4 from "../../../Assets/service-4.jpg";
import service5 from "../../../Assets/service-5.jpg";
import service6 from "../../../Assets/service-6.jpg";
import consult from "../../../Assets/consult.jpg";
import service7 from "../../../Assets/export.jpg";
export const services = [
  {
    id: Math.floor(Math.random() * 10000000) + "a63b3c2566",
    title: "Air Freight",
    description:
      "We will provide you a faster, more affordable and most reliable air freight shipping solutions. Our company is keen about her client's entire air cargo experience.",
    imagePath: service1,
    indebthDescription: `<p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Arial;">Our Air Freight Shipping service to Nigeria anchors a good number of shipping activities that satisfies the needs of individuals and corporate businesses. We can move all cargo of different sizes from all our locations, for you.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Arial;">DUROTRADE NIG LIMITED particularly offers all kinds of air-related shipping needs by both individuals and corporate organizations.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Arial;">In 10 years and counting, we have been providing real-time quality time-saving, cost-effective air freight service solutions for safe manufacturers, contractors, industries as well as merchandises in Nigeria, from within</span><span style="font-family: Arial;"> </span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Arial;"><strong>Africa, America, Asia, Europe and the Middle East</strong></span><span style="font-family: Arial;"> </span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Arial;">for suppliers of production machinery and some over-sized equipment.</span></p>
    `,

    url: "air-freight",
  },
  {
    id: Math.floor(Math.random() * 10000000) + "a63b3c2566",
    title: "Ocean / Sea Freight",
    description:
      "When cost matters and time is not an issue, DUROTRADE NIGERIA LIMITED's competitive sea freight shipping services are the best choice. especially when you want to keep costs down. ",
    imagePath: service2,
    indebthDescription: `<p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;">DUROTRADE NIG LIMITED offers time-saving, cost-effective sea freight shipping service solutions for manufacturers, contractors, industries as well as merchandises in Nigeria, either from within</span> <span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;"><strong>Africa, America, Asia, Europe and the Middle East</strong></span> <span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;">for suppliers of production machinery, over-sized equipment, and tractors.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;"><strong><em>Our sea freight shipping services include quality containerized shipment with guarantees for excellent shipping experience.</em></strong></span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;">With a growing number of container warehouses and loading facilities, we provide 20ft, standard 40ft, and 40ft high cube containers for shipments of general cargo by Sea. Also, we provide RORO, Groupage Shipping, otherwise known as Less than Container Load (LCL), FCL and Bulk shipping from over 40 Seaport to Tincan &amp; Apapa.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;">Unlike </span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: inherit;font-family: inherit;">FCL</span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;">, which usually has a flat rate per container, </span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: inherit;font-family: inherit;">LCL</span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;"> is charged based on the volume, set in cubic meters. </span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: inherit;font-family: inherit;">LCL</span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;"> shipping is a cost-effective solution for smaller shipments as you only need to pay for the volume space used. At the same time it almost always </span><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: inherit;font-family: inherit;">cheaper than</span> <span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;">air freight.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;"><strong><em>As Customs Licensed Agent, we've got you covered as you're our main reason for being in business .</em></strong></span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;">We considered offering your business more than 50% savings with our entire import process. You can't beat the price when you compare with others. This is a rather fair consideration when measured with convention.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: Cabin, sans-serif;">We render Customs Clearing services through TINCAN &amp; Apapa in Lagos, Nigeria, and provide perfect shipping Document (FORM M &amp; PAAR) for your shipment. </span>&nbsp;</p>
    `,
    url: "sea-freight",
  },
  {
    id: Math.floor(Math.random() * 10000000) + "a63b3c2566",
    title: "Road Freight",
    description:
      "We offer a variety of road freight services to enable you transport your goods in the most efficient and fastest way possible. We also take pride in offering reliable road freight solutions...",
    imagePath: service3,
    indebthDescription: ` <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: inherit;">We offer a variety of road freight and charter services to enable you transport your goods in the most efficient and fastest way possible. We also take pride in offering reliable road freight solutions that are competitively priced, designed to meet the specific needs of our client without any compromise. We have a team of road freight service, who are well trained, works around the clock with access to sufficient number of vehicles to offer you the best possible range of road delivery service options across the globe.</span></p>
     <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: inherit;">DUROTRADE NIG LIMITED collect virtually any size, shape, weight of goods from any location in the fastest time possible. Our ability to provide innovative and appropriate solutions is derived from a shared passion for client maximum satisfaction and combined experience of over 10years in the West African over border transport industry and as well as across the globe.</span></p>
     <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: inherit;">By providing vehicles of different sizes and capacities, ranging from light courier vans, to abnormal loads, tail lift, box and curtain sided vehicles, reefers, low loaders, skeleton trucks and container bodies, we take care of your every need when it comes to road freight and logistic services.</span></p>
     <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 18px;font-family: inherit;">Transit times are well thought of and scheduled to meet your requirements. We plan the most economical route whilst ensuring that we meet your deadline, also select the most suitable vehicle for your road transport service in order to avoid delays or unfavorable conditions that may come up as a result of choosing the wrong vehicle.</span>&nbsp;</p>
     `,
    url: "road-freight",
  },
  {
    id: Math.floor(Math.random() * 10000000) + "a63b3c2566",
    title: "Train Freight",
    description:
      "We offer a variety of train freight service to enable you move your goods in the most efficient way possible. We also take pride in offering reliable train freight solutions that are competitively priced...",
    imagePath: service4,
    indebthDescription: `<p><span style="color: rgb(0,0,0);font-size: 16px;font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji;">Trains are a popular and efficient mode of freight transportation, especially for large quantities of goods over long distances. We are DUROTRADE NIG LIMITED offers you a</span><span style="font-size: 16px;font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji;"> cost-effective mode of transportation, especially for long-distance freight. Trains can carry large volumes of goods in a single trip, reducing the overall cost per unit of freight.</span>&nbsp;</p>
    <p><span style="color: rgb(0,0,0);font-size: 16px;font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji;">Trains have significant capacity to carry a wide variety of goods, including raw materials, finished products, and even hazardous materials. Freight trains can consist of numerous cars, each designed to accommodate specific types of cargo.</span><span style="color: rgb(0,0,0);"> </span><span style="color: rgb(0,0,0); font-size: 16px;font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji;">Trains have significant capacity to carry a wide variety of goods, including raw materials, finished products, and even hazardous materials. Freight trains can consist of numerous cars, each designed to accommodate specific types of cargo.</span><span style="color: rgb(0,0,0);"> </span></p>
    <p><span style="color: rgb(0,0,0);font-size: 16px;font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji;">Trains are suitable for both bulk and containerized freight. Bulk goods like coal, minerals, and agricultural products can be transported in open cars, while containerized freight is placed in standardized containers for ease of handling and transfer between different modes of transportation.</span><span style="color: rgb(0,0,0);"> </span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);font-size: 1rem;font-family: Söhne, ui-sans-serif, system-ui, -apple-system, "Segoe UI", Roboto, Ubuntu, Cantarell, "Noto Sans", sans-serif, "Helvetica Neue", Arial, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji;">In summary, train freight plays a significant role in the global logistics and transportation of goods. Its efficiency, capacity, and cost-effectiveness make it a preferred choice for certain types of cargo and specific transport needs.</span></p>`,
    url: "train-freight",
  },
  {
    id: Math.floor(Math.random() * 10000000) + "a63b3c2566",
    title: "Customs Clearance",
    description:
      "DUROTRADE NIGERIA LIMITED is a professional Licensed Customs Agent in Nigeria, offering customs clearing services for imports and exports through Airport and Sea Ports.",
    imagePath: service5,
    indebthDescription: `<p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;">DUROTRADE NIG LIMITED is a professional Licensed Customs Agent in Nigeria, offering customs clearing services for imports and exports through</span> Airport<span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;"> and Sea Ports.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;">When your business relies on imported goods, you need to work with a shipping company that can get your merchandise through customs in a timely manner.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;"><strong>DUROTRADE NIG LIMITED</strong></span> <span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;">is made up of a team of highly-experienced Customs Brokers and Entry Writers. Our Brokers are licensed through the Nigerian Customs and Border Protection agency and are well-versed in tariff and customs laws, allowing them to foresee and avoid potential obstacles to customs clearance.</span>&nbsp;</p>
    `,

    url: "custom-clearance",
  },
  {
    id: Math.floor(Math.random() * 10000000) + "a63b3c2566",
    title: "Warehouse Solutions",
    description:
      "We offer flexible Warehousing solutions that will help you accelerate the increasing demand of your customers. Also, the service is engineered to improve clients' inventory efficiency",
    imagePath: service6,
    indebthDescription: `<p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;">We offer flexible Distribution and Warehousing solutions that will help you accelerate the increasing demand of your customers. Also, the service is engineered to improve clients' inventory efficiency.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;">As growing transportation and logistics services company providing reliable, cost-effective highway, intermodal, and supply chain solutions to businesses across Africa, U.S., Europe, Asia and UAE, our distribution and warehousing operations are tailored to your business' logistic needs.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;">Transloading, Warehousing and Distribution Solutions is one of our ever-expanding services, among which include Transload, with warehouses strategically located in Nigeria to facilitate just-in-time deliveries for our customers.</span>&nbsp;</p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;">With Customer Service Representatives (CSR’s), Outbound Coordinators, and Inbound Coordinators. You can count on responsive service and support from dedicated personnel who understand the specific requirements of your business, and your customers’ needs.</span></p>
    <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 17px;font-family: Cabin, sans-serif;">We are committed to continually evolving our service offerings to meet the ever-changing demands of today’s dynamic markets.</span>&nbsp;</p>
    `,
    url: "warehouse-solution",
  },

  {
    id: Math.floor(Math.random() * 10000000) + "a63b3c2566",
    title: "Import & Export",
    description:
      "Experience a new standard of efficiency as we streamline the import and export process for you. Our team of seasoned professionals meticulously handles documentation, customs clearance, and logistics, ensuring your goods reach their destination promptly.",
    imagePath: service7,
    indebthDescription: `<p><span style="font-size: 16px;">Embark on a seamless international trade journey with our cutting-edge import and export services. We bridge borders, connecting businesses worldwide while leveraging our local expertise to ensure a smooth and efficient process.</span></p>
    <p></p>
    <p><span style="font-size: 16px;">At the heart of our service is a commitment to building lasting partnerships. We collaborate with a network of trusted suppliers, carriers, and customs authorities to guarantee the reliability and security of your shipments. Our partnerships enable us to navigate the complexities of international trade with precision.</span></p>
    <p><span style="font-size: 16px;">Experience a new standard of efficiency as we streamline the import and export process for you. Our team of seasoned professionals meticulously handles documentation, customs clearance, and logistics, ensuring your goods reach their destination promptly and in pristine condition.</span></p>
    <p></p>
    <p><span style="font-size: 16px;">Tailored to meet your unique needs, our comprehensive logistics solutions cover air, sea, and land transport. From door-to-door shipping to multimodal transportation, we provide a range of options designed to optimize cost and time efficiency for your business.</span></p>
    <p></p>
    <p><span style="font-size: 16px;">Navigating international regulations can be daunting, but with our dedicated compliance team, rest assured that your shipments comply with all relevant laws and regulations. We take care of the paperwork, so you can focus on growing your business without worrying about legal complexities.</span></p>
    <p></p>
    <p><span style="font-size: 16px;">Harness the power of data with our advanced tracking and analytics tools. Monitor the real-time progress of your shipments, access historical data, and gain valuable insights to make informed business decisions. Our technology-driven approach keeps you in control every step of the way.</span></p>
    <p></p>
    <p><span style="font-size: 16px;">Your success is our priority. Our customer-centric approach means you receive personalized support from a dedicated team committed to exceeding your expectations. Experience a service that goes beyond the transaction to build a lasting relationship.</span></p>
    <p></p>
    <p><span style="font-size: 16px;">Choose <strong><em>DUROTRADE NIGERIA LIMITED</em></strong> for a world-class import and export experience. Let us be your strategic partner in navigating the global marketplace with confidence and ease.</span></p>
     `,
    url: "import-&-export",
  },

  {
    id: Math.floor(Math.random() * 1000000) + "jsgiuwotiuls",
    title: "Consultancy",
    imagePath: consult,
    description:
      "Everyone is entitled to obtaining the right information. Looking out for solutions to logistics problems out there might be quite a dicey venture. To take care of misconception, we decided to give everybody a chance to the right information, one call away.",
    indebthDescription: `<p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 20px;font-family: Cabin;">Everyone is entitled to obtaining the right information. Looking out for solutions to logistics problems out there might be quite a dicey venture.</span></p>
  <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 20px;font-family: Cabin;">To take care of misconception, we decided to give everybody a chance to the right information, one call away.</span></p>
  <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 20px;font-family: Cabin;">Our consultancy service is absolutely free.</span></p>
  <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 20px;font-family: Cabin;">Our qualified freight forwarding Consultancy Personnel will be available to advise and guide you from start to finish. We are focused on ensuring that every caller and visitors/client receives the best consultation services ever.</span></p>
  <p style="text-align:start;"><span style="color: rgb(0,0,0);background-color: rgb(255,255,255);font-size: 20px;font-family: Cabin;">Our Representatives analyses the needs of the client from the very first contact. In particular, during the technical visit, their expertise is used to assess the weight and volume of the consignment, as well as consider other factors which may affect the move, such as loading restrictions, access to the property, or tight corners.</span>&nbsp;</p>`,
    url: "consultancy",
  },
];
