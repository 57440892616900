import React, { useState } from "react";
import "../Home.css";
import Carousel1 from "./Carousel1";
import Carousel2 from "./Carousel2";

const Intro = () => {
  const [showCarousel, setShowCarousel] = useState(false);

  const toggleCarousel = () => {
    if (showCarousel) {
      setShowCarousel(false);
    } else {
      setShowCarousel(true);
    }
  };

  return (
    <div className="container-fluid p-0 pb-5 intro">
      <div className="position-relative mb-5 owl-carousel header-carousel">
        {showCarousel && <Carousel1 onToggleCarousel={toggleCarousel} />}
        {!showCarousel && <Carousel2 onToggleCarousel={toggleCarousel} />}
      </div>
    </div>
  );
};

export default Intro;
