import about from "../../../Assets/Lombok_Strait.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="container-fluid overflow-hidden py-5 px-lg-0">
      <div className="container about py-5 px-lg-0">
        <AnimationOnScroll animateIn="animate__fadeInRight">
          <div className="row g-5 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0 wow fadeInLeft"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100">
                <img
                  className="position-absolute img-fluid w-100 h-100"
                  src={about}
                  style={{ objectFit: "cover" }}
                  alt=""
                />
              </div>
            </div>

            <div
              className="col-lg-6 about-text wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <h6 className="text-secondary text-uppercase mb-3">About Us</h6>
              <h1 className="mb-5">
                Quick Shipping, Logistics, Custom clearance and inland
                transportation services
              </h1>
              <p className="mb-5">
                We will provide you a faster, more affordable and most reliable
                solutions. Our company is keen about her client's overall
                experience.
              </p>
              <div className="row g-4 mb-5">
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.5s">
                  <i className="fa fa-globe fa-3x text-primary mb-3"></i>
                  <h5>Global Coverage</h5>
                </div>
                <div className="col-sm-6 wow fadeIn" data-wow-delay="0.7s">
                  <i className="fa fa-shipping-fast fa-3x text-primary mb-3"></i>
                  <h5>On Time Delivery</h5>
                </div>
              </div>
              <Link
                to="/about-us"
                className="btn btn-primary py-3 px-5"
                title="Read more about us and the services we render"
              >
                Read More...
              </Link>{" "}
            </div>
          </div>
        </AnimationOnScroll>
      </div>
    </div>
  );
};

export default AboutUs;
