import React from "react";

const Navigator = ({ onToggleCarousel }) => {
  return (
    <div className="icon-d">
      <div className="icon" onClick={onToggleCarousel}>
        <i className="bi bi-chevron-right"></i>
      </div>
    </div>
  );
};

export default Navigator;
