import { Fragment } from "react";
import Intro from "../components/Home/Intro/Intro";
import AboutUs from "../components/Home/AboutUs/AboutUs";
import Services from "../components/Home/Services/Services";
import Features from "../components/Home/Featrues/Features";
import Support from "../components/Home/Support/Support";
import Team from "../components/Home/Team/Team";

const HomePage = () => {
  return (
    <Fragment>
      <Intro />
      <AboutUs />
      <Services />
      <Features />
      <Team />
      <Support />
    </Fragment>
  );
};

export default HomePage;
